
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { OrganizationModule } from '@/modules/organization/store'

import ProfileBadge from '../components/profile-badge.vue'
import * as analytics from '../helpers/analytics'
import { UserModule } from '../store'

@Component({
  components: {
    ProfileBadge
  },
  name: 'UserProfileSetup'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  loading = false
  error = ''

  name = ''
  jobRole = ''
  referredBy = ''
  c4ModelExperience = ''

  nameFocused = false
  jobRoleFocused = false
  referredByFocused = false
  c4ModelExperienceFocused = false

  get completeProfileDisabled () {
    return !this.name || !this.jobRole || !this.referredBy || !this.c4ModelExperience
  }

  get referredItems (): string[] {
    return [
      ...[
        'Someone told me about you',
        'Search engine',
        'Social media',
        'Google Advert',
        'Medium',
        'c4model.com',
        'softwarearchitecture.tools'
      ].sort(() => (Math.random() > 0.5) ? 1 : -1),
      'Other'
    ]
  }

  get c4ModelExperienceItems (): string[] {
    return [
      'I\'ve never heard of it',
      'I\'ve heard of it but never used it',
      'I diagram using C4 sometimes',
      'I diagram using C4 all the time'
    ].sort(() => (Math.random() > 0.5) ? 1 : -1)
  }

  async checkReferrer (referrer: string) {
    if (referrer.toLowerCase().trim() === 'other') {
      await this.$nextTick()
      this.referredBy = ''
    }
  }

  created () {
    this.name = this.userModule.user?.name ?? ''
    this.jobRole = this.userModule.user?.jobRole ?? ''
    this.referredBy = this.userModule.user?.referredBy ?? ''
    this.c4ModelExperience = this.userModule.user?.c4ModelExperience ?? ''
  }

  mounted () {
    analytics.userProfileSetupScreen.track(this)
  }

  async completeProfile () {
    if (this.completeProfileDisabled) {
      return
    }

    try {
      this.loading = true

      if (!this.userModule.user) {
        throw new Error('User not found')
      }

      let referredBy: string | undefined
      if (this.referredBy === 'Someone told me about you') {
        referredBy = 'Word of mouth'
      } else if (this.referredBy) {
        referredBy = this.referredBy
      }

      const prevUser = { ...this.userModule.user }

      await this.userModule.userUpdate({
        c4ModelExperience: this.c4ModelExperience || undefined,
        jobRole: this.jobRole || undefined,
        name: this.name || undefined,
        referredBy
      })

      analytics.userUpdate.track(this, {
        userC4ModelExperience: prevUser.c4ModelExperience,
        userEmail: prevUser.email,
        userJobRole: prevUser.jobRole,
        userNotifications: prevUser.notifications,
        userReferredBy: prevUser.referredBy,
        userReferredByOrganizationId: prevUser.referredByOrganizationId,
        userReferredByUserId: prevUser.referredByUserId,
        userUpdateC4ModelExperience: prevUser.c4ModelExperience !== this.c4ModelExperience ? this.c4ModelExperience : undefined,
        userUpdateJobRole: prevUser.jobRole !== this.jobRole ? this.jobRole : undefined,
        userUpdateName: prevUser.name !== this.name ? this.name : undefined,
        userUpdateReferredBy: prevUser.referredBy !== referredBy ? referredBy : undefined
      })

      const organizationId = this.organizationModule.organizations[0]?.id
      if (organizationId) {
        await this.$router.replace({
          name: 'landscapes',
          params: {
            organizationId
          }
        })
      } else {
        await this.$router.push({
          name: 'landscape-setup'
        })
      }
    } catch (err: any) {
      this.error = err.body?.message || err.message
    } finally {
      this.loading = false
    }
  }
}
