
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'UserProfileBadge'
})
export default class extends Vue {
  @Prop() readonly name!: string
  @Prop() readonly jobRole!: string
}
